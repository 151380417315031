import React from 'react'
import { Link } from 'gatsby'

import './PostCard.css'

const PublikasiCard = ({
  title,
  tahun,
  link,
  pdf,
  slug,
  categories = [],
  className = '',
  ...props
}) => (
  

    <div className="PostCard--Content" style={{ border: 'solid #0a0a0a' }}>
      <Link to={slug} className={`PostCard ${className}`}>
          <br />
          {title && <h3 className="PostCard--Title">{title}</h3>}
          <div className="PostCard--Category">
          <span className="subtitle is-size-6 is-block"> {categories && categories.map(cat => cat.category).join(', ')} {tahun} </span>
          </div>
      </Link>
        {link && <a href={link} target="_blank"><button style={{ marginTop: '0', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>Unduh</button></a>}
        {pdf && <a href={link} target="_blank"><button style={{ marginTop: '0', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>Unduh</button></a>}

    </div>
  
)

export default PublikasiCard
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/PageHeader'
import PublikasiSection from '../components/PublikasiSection'
import PublikasiCategoriesNav from '../components/PublikasiCategoriesNav'
import Layout from '../components/Layout'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = publikasi => {
  const now = Date.now()
  return publikasi.filter(post => Date.parse(post.tahun) <= now)
}

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (publikasi, title, contentType) => {
  const isCategory = contentType === 'kategoriPublikasi'
  const byCategory = post =>
    post.categories &&
    post.categories.filter(cat => cat.category === title).length
  return isCategory ? publikasi.filter(byCategory) : publikasi
}

// Export Template for use in CMS preview
export const PublikasiIndexTemplate = ({
  title,
  subtitle,
  featuredimage,
  publikasi = [],
  kategoriPublikasi = [],
  enableSearch = true,
  contentType
}) => (
  <Location>
    {({ location }) => {
      let filteredPublikasi =
        publikasi && !!publikasi.length
          ? byCategory(byDate(publikasi), title, contentType)
          : []

      let queryObj = location.search.replace('?', '')
      queryObj = qs.parse(queryObj)

      if (enableSearch && queryObj.s) {
        const searchTerm = queryObj.s.toLowerCase()
        filteredPublikasi = filteredPublikasi.filter(post =>
          post.frontmatter.title.toLowerCase().includes(searchTerm)
        )
      }

      return (
        <main className="Blog">
          <PageHeader
            title={title}
            subtitle={subtitle}
          />

          {!!kategoriPublikasi.length && (
            <section className="section thin">
              <div className="container">
                <PublikasiCategoriesNav enableSearch categories={kategoriPublikasi} />
              </div>
            </section>
          )}

          {!!publikasi.length && (
            <section className="section">
              <div className="container">
                <PublikasiSection publikasi={filteredPublikasi} />
              </div>
            </section>
          )}
        </main>
      )
    }}
  </Location>
)

PublikasiIndexTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

// Export Default BlogIndex for front-end
const PublikasiIndex = ({ data: { page, publikasi, kategoriPublikasi } }) => (
  <Layout>
    <PublikasiIndexTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      publikasi={publikasi.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      kategoriPublikasi={kategoriPublikasi.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)



export default PublikasiIndex

export const pageQuery = graphql`

  query PublikasiIndex($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      fields {
        contentType
      }
      frontmatter {
        title
        templateKey
        subtitle
      }
    }

    publikasi: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "publikasi" } } }
      sort: { order: DESC, fields: [frontmatter___tahun] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tahun
            link
            pdf
            categories {
              category
            }
          }
        }
      }
    }
    kategoriPublikasi: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "kategoriPublikasi" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }

`
